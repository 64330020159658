<script>
export default {
    name: 'nn-comment',
    props: {
        data: Object,
        editable: Number,
        loggedIn: String,
    },
    data() {
        return {
            currentVotes: {},
            currentVote: '',
        }
    },
    mounted(){
        this.updateVoteCount({
            up_votes: this.data.up_votes,
            down_votes: this.data.down_votes
        }),
            this.updateUserVote(this.data.voted)
    },
    methods: {
        async vote(id, type) {
            if (type !== this.currentVote) {
                try {
                    let response = await axios.post('/comment/'+ id +'/vote', {'type' : type})
                    if (response.data.votes) {
                        this.updateVoteCount(response.data.votes)
                    }
                    if (response.data.user_vote !== this.currentVote && response.data.user_vote !== 'undefined') {
                        this.updateUserVote(response.data.user_vote)
                    }
                    this.$toastr.s("Gelukt!", response.data.message)

                } catch (error) {
                    if (error.response.status === 403) {
                        this.$toastr.e("Fout!", error.response.data.message)
                    }
                }
            } else {
                this.$toastr.w("Let op!", 'U heeft al gestemd.')
            }
        },
        updateVoteCount(votes){
            this.currentVotes = votes
        },
        updateUserVote(vote){
            this.currentVote = vote
        },
        sharePost(id) {
            console.log('Sharing post: ' + id)
        },
    },
}
</script>

<template>
    <div class="row d-flex justify-content-end nn-comment-wrapper">
        <div class="col col-md-10 nn-comment-content">
            <div class="card nn-comment-card mb-2">
                <div class="card-body nn-comment">
                    <div class="nn-comment-header row">
                        <div class="col">
                            <a :href=" '/post/' + data.id ">
                                <h5 class="nn-comment-header-title">{{ data.author }}</h5>
                            </a>
                            <p class="nn-comment-header-subtitle">{{ data.created_at }}</p>
                        </div>
                        <div class="col">
                            <div class="nn-comment-header-votes d-flex justify-content-end">
                                <span class="nn-comment-vote-up nn-comment-vote" @click="vote(data.id, 'up')">
                                    <i class="fas fa-arrow-alt-circle-up" :class="currentVote === 'up' ? 'text-success' : '' "></i> {{ currentVotes.up_votes }}
                                </span>
                                <span class="nn-comment-vote-down nn-comment-vote" @click="vote(data.id, 'down')">
                                    <i class="fas fa-arrow-alt-circle-down" :class="currentVote === 'down' ? 'text-danger' : '' "></i> {{ currentVotes.down_votes }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="nn-comment-body">
                        <p>{{ data.body }}</p>
                        <p>{{ data.url }}</p>
                    </div>
                </div>
                <div v-if="editable" class="nn-post-admin-actions border d-flex justify-content-between">
                    <div>
                        Admin functions
                    </div>
                    <div>
                        <slot name="right">
                            Action slot
                        </slot>
                    </div>
                </div>
                <div class="card-footer d-flex" @click="sharePost(data.id)">
                    <div class="w-50">
                        <p>X reacties</p>
                    </div>
                    <div class="w-50">
                        <p><i class="fas fa-share"></i>  Deel deze post</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

