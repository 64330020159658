<template>
    <div class="row nn-post-wrapper">
        <div class="col nn-post-content">
            <div class="card nn-post-card mb-2">
                <div class="card-body nn-post">
                    <div class="nn-post-header row">
                        <div class="col">
                            <a :href=" '/post/' + currentPost.id ">
                                <h3 class="nn-post-header-title">{{ currentPost.author }}</h3>
                            </a>
                            <p class="nn-post-header-subtitle">{{ currentPost.created_at }}</p>
                        </div>
                        <div class="col">
                            <div class="nn-post-header-votes d-flex justify-content-end">
                                <span class="nn-post-vote-up nn-post-vote" @click="vote(currentPost.id, 'up')">
                                    <i :class="currentVote === 'up' ? 'text-success' : '' "
                                       class="fas fa-arrow-alt-circle-up"></i> {{
                                        currentVotes.up_votes
                                    }}
                                </span>
                                <span class="nn-post-vote-down nn-post-vote" @click="vote(currentPost.id, 'down')">
                                    <i :class="currentVote === 'down' ? 'text-danger' : '' "
                                       class="fas fa-arrow-alt-circle-down"></i> {{
                                        currentVotes.down_votes
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="nn-post-img-wrapper">
                        <transition name="fade">
                            <a v-if="currentPost.image_set" :href=" '/post/' + currentPost.id ">
                                <img :src=" currentPost.image_path "/>
                            </a>
                            <img v-else alt="Niksnuttigs placeholder image" src="/img/nn_placeholder_image.svg">
                        </transition>
                    </div>
                    <div class="nn-post-body">
                        <p>{{ currentPost.body }}</p>
                        <p>{{ currentPost.url }}</p>
                    </div>
                </div>
                <div v-if="editable" class="nn-post-admin-actions border d-flex justify-content-between">
                    <div>
                    </div>
                    <div>
                        <slot name="right">
                            Action slot
                        </slot>
                    </div>
                </div>
                <div class="card-footer d-flex" @click="sharePost(currentPost.id)">
                    <div class="w-50">
                        <p>{{ currentPost.comment_count }} reacties</p>
                    </div>
                    <div class="w-50">
                        <p><i class="fas fa-share"></i> Deel deze post</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'nn-post',
    props: {
        data: Object,
        editable: Number,
    },
    data() {
        return {
            currentVotes: {},
            currentVote: '',
            currentPost: {},
        }
    },
    created() {
        this.currentPost = this.data
        this.updateVoteCount({
            up_votes: this.data.up_votes,
            down_votes: this.data.down_votes
        })
        this.updateUserVote(this.data.voted)
    },
    methods: {
        async vote(id, type) {
            if (type !== this.currentVote) {
                try {
                    let response = await axios.post('/post/' + id + '/vote', {'type': type})
                    if (response.data.votes) {
                        this.updateVoteCount(response.data.votes)
                    }
                    if (response.data.user_vote !== this.currentVote && response.data.user_vote != 'undefined') {
                        this.updateUserVote(response.data.user_vote)
                    }
                    this.$toastr.s("Gelukt!", response.data.message)

                } catch (error) {
                    if (error.response.status === 403) {
                        this.$toastr.e("Fout!", error.response.data.message)
                    }
                }
            } else {
                this.$toastr.w("Let op!", 'U heeft al gestemd.')
            }
        },
        async fetchPost(id) {
            try {
                let response = await axios.get('/post/' + id)
                console.log(response)
                if (response.status === 200) {
                    this.currentPost = response.data
                }
            } catch (error) {
                console.log(error)
            }
        },
        updateVoteCount(votes) {
            this.currentVotes = votes
        },
        updateUserVote(vote) {
            this.currentVote = vote
        },
        sharePost(id) {
            console.log('Sharing post: ' + id)
        },
        updateImage() {
            console.log('Updating post: ' + this.data.id)
            this.fetchPost(this.currentPost.id)
        }
    },
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s
}

.fade-enter,
.fade-leave-to
    /* .fade-leave-active in <2.1.8 */

{
    opacity: 0
}
</style>
