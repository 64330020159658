<template>
    <div id="nn-posts-list">
        <transition-group name="fade" tag="div" type="out-in">
            <nn-post v-for="post in posts" :key="post.id " :ref="'post_'+post.id" :data="post"
                     :editable="post.editable">
                <template v-slot:right>
                    <button class="btn btn-danger" @click="deletePost(post.id)"><i class="fas fa-trash"></i></button>
                </template>
            </nn-post>
        </transition-group>
        <div v-if="posts.length === 0">
            <p>Geen posts</p>
        </div>
        <infinite-loading @distance="1" @infinite="handleLoadMore"></infinite-loading>
    </div>
</template>

<script>
import NnPost from './Post'

export default {
    name: 'nn-posts-component',
    components: {
        NnPost
    },
    props: {
        filter: String
    },
    data() {
        return {
            posts: [],
            page: 1,
            url: ''
        }
    },
    created() {
        switch (this.filter) {
            case 'up-votes':
                this.url = '/posts?filter=up-votes&page='
                break
            case 'down-votes':
                this.url = '/posts?filter=down-votes&page='
                break
            default:
                this.url = '/posts?page='
                break
        }
        //use your own credentials you get from Pusher
        let pusher = new Pusher('78c14f3af3d5088bad4b', {
            authEndpoint: '/broadcasting/auth',
            cluster: 'eu',
            encrypted: false,
        });

        //Subscribe to the channel we specified in our Adonis Application
        let channel = pusher.subscribe('private-screenshot-channel')

        channel.bind('newScreenshot', (data) => {
            this.$toastr.s("EVENT!", data.message)
            this.triggerImageUpdate(data.post_id)
        })
    },
    methods: {
        async fetchPosts() {
            try {
                let response = await axios.get('/posts')
                this.posts = response.data.data
            } catch (error) {
                console.log(error)
            }
        },
        async deletePost(id) {
            try {
                let response = await axios.delete('/post/' + id)
                if (response.status === 200) {
                    const index = this.posts.findIndex(e => e.id === id)
                    this.posts.splice(index, 1)
                    this.$toastr.s("Gelukt!", response.data.message)
                }
            } catch (error) {
                console.log(error)
                this.$toastr.e("Fout!", 'Er is iets fout gegaan.')
            }
        },
        handleLoadMore($state) {
            axios.get(this.url + this.page).then(
                response => {
                    let items = response.data.data
                    items.forEach((element) => {
                        this.posts.push(element)
                    })
                    $state.loaded();
                }
            )
            this.page = this.page + 1;
        },
        triggerImageUpdate(id) {
            const refName = `post_${id}`
            if (this.$refs[refName] !== 'undefined') {
                this.$refs[refName][0].updateImage()
            }
        },
    }
}
</script>

<style>
.fade-leave-active {
    transition: all .4s;
}

.fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
    /*transform: translateY(-100px);*/
    margin-bottom: -350px;
}
</style>
