<template>
    <div id="nn-main-nav" v-click-outside="closeForm">
        <div class="bg-nn-primary">
            <div class="container py-2">
                <div class="row">
                    <slot name="top">
                        Top navigation
                    </slot>
                </div>
            </div>
        </div>
        <template v-if="loggedIn === '1'">
            <transition name="dropdown">
                <div v-if="showPostForm" key="post" class="bg-nn-primary-lighter">
                    <div class="container">
                        <slot name="bottom">
                            Bottom navigation
                        </slot>
                    </div>
                </div>
                <div v-else key="placeholder" class="bg-nn-primary-lighter" @click="toggleCreatePost">
                    <div class="container">
                        <slot name="placeholder">
                            placeholder
                        </slot>
                    </div>
                </div>
            </transition>
        </template>
        <template v-else>
            <div class="bg-nn-primary-lighter">
                <div class="container">
                    <p class="py-3">Log in om een bericht te plaatsen.</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'nn-header',
    props: {
        hasErrors: String,
        loggedIn: String,
    },
    data() {
        return {
            showPostForm: false,
        }
    },
    methods: {
        toggleCreatePost() {
            this.showPostForm = !this.showPostForm
        },
        closeForm(event) {
            this.showPostForm = false
        }
    },
    mounted() {
        if (this.hasErrors) {
            this.toggleCreatePost();
        }
    }
}
</script>

<style>
.dropdown-enter-active {
    transition: all 300ms;
    max-height: 400px;
}

.dropdown-enter {
    opacity: 0;
    max-height: 0px;
}
</style>
