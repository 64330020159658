<template>
    <div id="nn-post-filter">
        <div class="list-group">
            <a href="/" class="list-group-item list-group-item-action" :class="this.filter === '' ? 'active':'' ">Laatst toegevoegd</a>
            <a href="/?filter=up-votes" class="list-group-item list-group-item-action" :class="this.filter === 'up-votes' ? 'active':'' ">Meeste Upvotes</a>
            <a href="/?filter=down-votes" class="list-group-item list-group-item-action" :class="this.filter === 'down-votes' ? 'active':'' ">Meeste Downvotes</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'nn-post-filter',
    props: {
        filter: String
    },
}
</script>
