require('./bootstrap');

import Vue from 'vue'
import NnHeader from './components/Header.vue'
import NnFooter from './components/Footer.vue'
import NnPostFilter from './components/PostFilter.vue'
import NnPost from './components/Post.vue'
import NnPostsComponent from './components/PostsComponent.vue'
import NnComment from './components/Comment.vue'
import VueToastr from "vue-toastr";
import VueResource from 'vue-resource';
import InfiniteLoading from "vue-infinite-loading";
import CookieLaw from 'vue-cookie-law'


Vue.use(VueResource);
Vue.use(VueToastr);
Vue.use(InfiniteLoading);


Vue.directive('click-outside', {
    bind: function (element, binding, vnode) {
        element.clickOutsideEvent = function (event) {  //  check that click was outside the el and his children
            if (!(element === event.target || element.contains(event.target))) { // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
                // binding.value(); run the arg
            }
        };
        document.body.addEventListener('click', element.clickOutsideEvent)
    },
    unbind: function (element) {
        document.body.removeEventListener('click', element.clickOutsideEvent)
    }
});

new Vue({
    el: '#app',
    components: {
        NnHeader,
        NnFooter,
        NnPostFilter,
        NnPost,
        NnComment,
        NnPostsComponent,
        CookieLaw,
    },
})
